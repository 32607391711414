<template>
  <!-- <teleport to="#app"> -->
    <!-- <div class="bg-magnitude text-gray-300 text-lg flex items-center justify-between fixed top-0 w-full z-10"> -->
    <div class="bg-magnitude text-lg fixed top-0 w-full z-10" id="navwrap">
      <div class="menu-wrapper absolute w-screen h-screen bg-amaranth z-30 opacity-0 hidden overflow-auto"> 
        <div class="menu-items mode-center text-umbra mx480:block h-full flex justify-between flex-col">
          <div class="inline-block mt-36">
            <router-link
              to="/"
              @click="toggleMenu('current-week')"
              class="text-6xl font-delta mx900:text-3xl mx640:text-2xl flex items-end group-menu mb-10 text-magnitude transition duration-300 ease-in-out transform hover:text-perfume hover:scale-105 hover:translate-x-3 noHighlight mn900:items-baseline"
            >
              Huidige week
              <MenuArrow class="ml-6 mx900:w-7 mx900:h-7 mx640:w-4 mx640:h-4 arrow" id="arrow_current-week" />
            </router-link>
            <router-link
              v-for="(item, index) in menu" :key="index"
              :to="item.path"
              @click="toggleMenu(index)"
              class="text-6xl font-delta mx900:text-3xl mx640:text-2xl flex items-center group-menu mb-10 text-magnitude transition duration-300 ease-in-out transform hover:text-perfume hover:scale-105 hover:translate-x-3 noHighlight mn900:items-baseline"
            >
              {{ item.title }}
              <MenuArrow class="ml-6 mx900:w-7 mx900:h-7 mx640:w-4 mx640:h-4 arrow" :id="'arrow_' + index" />
            </router-link>
          </div>
          <!-- <div class="mb-8 border-t border-white text-white">
            <div class="inline-block mt-3">
              <div class="flex items-center">
                <div v-if="globals.data.footer.twitter" class="pr-4 group-menu">
                  <a :href="globals.data.footer.twitter" target="_blank">
                    <svg class=" w-6 h-6 mn900:w-14 mn900:h-14" viewBox="0 0 22 22" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path class="group-menu-hover:text-perfume fill-current text-white transition duration-300 ease-in-out" fill-rule="evenodd" clip-rule="evenodd" d="M20 5.63381C19.3743 5.91106 18.7029 6.09908 17.9975 6.18303C18.7178 5.75172 19.2691 5.06761 19.5304 4.25494C18.8548 4.65436 18.1091 4.94439 17.3144 5.10161C16.6781 4.42281 15.7731 4 14.7692 4C12.8432 4 11.2816 5.56158 11.2816 7.48647C11.2816 7.75947 11.3124 8.02611 11.3719 8.28108C8.474 8.13553 5.90431 6.74711 4.18444 4.63736C3.88381 5.15153 3.71278 5.75064 3.71278 6.39017C3.71278 7.60014 4.32892 8.66775 5.26375 9.29236C4.69222 9.27325 4.15469 9.11603 3.68411 8.85469V8.89825C3.68411 10.5873 4.88664 11.997 6.48114 12.3178C6.189 12.3964 5.88094 12.44 5.56225 12.44C5.33706 12.44 5.11928 12.4177 4.90575 12.3752C5.34978 13.7615 6.63731 14.7696 8.16278 14.7972C6.96981 15.7321 5.46558 16.2876 3.83178 16.2876C3.55028 16.2876 3.273 16.2706 3 16.2398C4.54353 17.231 6.376 17.8089 8.3455 17.8089C14.7607 17.8089 18.2674 12.4952 18.2674 7.88697L18.2557 7.4355C18.9409 6.94681 19.5336 6.33281 20 5.63381Z" fill="#D32232"/>
                        <mask id="mask0_321_450" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="4" width="17" height="14">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20 5.63381C19.3743 5.91106 18.7029 6.09908 17.9975 6.18303C18.7178 5.75172 19.2691 5.06761 19.5304 4.25494C18.8548 4.65436 18.1091 4.94439 17.3144 5.10161C16.6781 4.42281 15.7731 4 14.7692 4C12.8432 4 11.2816 5.56158 11.2816 7.48647C11.2816 7.75947 11.3124 8.02611 11.3719 8.28108C8.474 8.13553 5.90431 6.74711 4.18444 4.63736C3.88381 5.15153 3.71278 5.75064 3.71278 6.39017C3.71278 7.60014 4.32892 8.66775 5.26375 9.29236C4.69222 9.27325 4.15469 9.11603 3.68411 8.85469V8.89825C3.68411 10.5873 4.88664 11.997 6.48114 12.3178C6.189 12.3964 5.88094 12.44 5.56225 12.44C5.33706 12.44 5.11928 12.4177 4.90575 12.3752C5.34978 13.7615 6.63731 14.7696 8.16278 14.7972C6.96981 15.7321 5.46558 16.2876 3.83178 16.2876C3.55028 16.2876 3.273 16.2706 3 16.2398C4.54353 17.231 6.376 17.8089 8.3455 17.8089C14.7607 17.8089 18.2674 12.4952 18.2674 7.88697L18.2557 7.4355C18.9409 6.94681 19.5336 6.33281 20 5.63381Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_321_450)">
                        </g>
                    </svg>
                  </a>
                </div>

                <div v-if="globals.data.footer.facebook" class="pr-4 group-menu">
                  <a :href="globals.data.footer.facebook" target="_blank">
                    <svg class=" w-6 h-6 mn900:w-14 mn900:h-14" viewBox="0 0 22 22" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path class="group-menu-hover:text-perfume fill-current text-white transition duration-300 ease-in-out" fill-rule="evenodd" clip-rule="evenodd" d="M12.4986 19V11.7022H14.995L15.3695 8.85725H12.4986V7.04118C12.4986 6.21776 12.7308 5.65661 13.936 5.65661L15.4706 5.65599V3.11138C15.2052 3.07756 14.2942 3 13.2339 3C11.0198 3 9.50399 4.32557 9.50399 6.75942V8.85725H7V11.7022H9.50399V19H12.4986Z" fill="#D32232"/>
                        <mask id="mask0_321_447" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="7" y="3" width="9" height="16">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4986 19V11.7022H14.995L15.3695 8.85725H12.4986V7.04118C12.4986 6.21776 12.7308 5.65661 13.936 5.65661L15.4706 5.65599V3.11138C15.2052 3.07756 14.2942 3 13.2339 3C11.0198 3 9.50399 4.32557 9.50399 6.75942V8.85725H7V11.7022H9.50399V19H12.4986Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_321_447)">
                        </g>
                    </svg>        
                  </a>
                </div>

                <div v-if="globals.data.footer.instagram" class="pr-4 group-menu">
                  <a :href="globals.data.footer.instagram" target="_blank">
                    <svg class=" w-6 h-6 mn900:w-14 mn900:h-14" viewBox="0 0 22 22" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path class="group-menu-hover:text-perfume fill-current text-white transition duration-300 ease-in-out" fill-rule="evenodd" clip-rule="evenodd" d="M6.96724 2H15.0325C17.7717 2 20.0001 4.22841 20 6.96735V15.0327C20 17.7716 17.7717 20 15.0325 20H6.96724C4.22831 20 2 17.7717 2 15.0327V6.96735C2 4.22841 4.22831 2 6.96724 2ZM15.0326 18.403C16.8911 18.403 18.4031 16.8911 18.4031 15.0326H18.403V6.96735C18.403 5.10903 16.891 3.59705 15.0325 3.59705H6.96724C5.10892 3.59705 3.59705 5.10903 3.59705 6.96735V15.0326C3.59705 16.8911 5.10892 18.4031 6.96724 18.403H15.0326ZM6.28571 11.0001C6.28571 8.40055 8.4005 6.28571 11 6.28571C13.5995 6.28571 15.7143 8.40055 15.7143 11.0001C15.7143 13.5996 13.5995 15.7143 11 15.7143C8.4005 15.7143 6.28571 13.5996 6.28571 11.0001ZM7.90898 11C7.90898 12.7044 9.29568 14.0909 11 14.0909C12.7043 14.0909 14.091 12.7044 14.091 11C14.091 9.29553 12.7044 7.90891 11 7.90891C9.29557 7.90891 7.90898 9.29553 7.90898 11Z" fill="#D32232"/>
                        <mask id="mask0_321_456" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="18" height="18">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.96724 2H15.0325C17.7717 2 20.0001 4.22841 20 6.96735V15.0327C20 17.7716 17.7717 20 15.0325 20H6.96724C4.22831 20 2 17.7717 2 15.0327V6.96735C2 4.22841 4.22831 2 6.96724 2ZM15.0326 18.403C16.8911 18.403 18.4031 16.8911 18.4031 15.0326H18.403V6.96735C18.403 5.10903 16.891 3.59705 15.0325 3.59705H6.96724C5.10892 3.59705 3.59705 5.10903 3.59705 6.96735V15.0326C3.59705 16.8911 5.10892 18.4031 6.96724 18.403H15.0326ZM6.28571 11.0001C6.28571 8.40055 8.4005 6.28571 11 6.28571C13.5995 6.28571 15.7143 8.40055 15.7143 11.0001C15.7143 13.5996 13.5995 15.7143 11 15.7143C8.4005 15.7143 6.28571 13.5996 6.28571 11.0001ZM7.90898 11C7.90898 12.7044 9.29568 14.0909 11 14.0909C12.7043 14.0909 14.091 12.7044 14.091 11C14.091 9.29553 12.7044 7.90891 11 7.90891C9.29557 7.90891 7.90898 9.29553 7.90898 11Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_321_456)">
                        </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div> -->
        </div>  
      </div>
      <div class="mode-center">
        <div class="flex justify-between items-center mt-2">
          <router-link class="mr-6 inline-block p-2 hover:bg-opacity-50 transition duration-300 ease-in-out" to="/">
            <!-- <svg class="w-9 h-9 transform hover:animate-pulse hover:scale-110 hover:rotate-180 transition-all duration-500 ease-in-out" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"></path></svg> -->
            <Logo />
          </router-link>
          <div @click="toggleMenu('x')" class="hamburger-button top-0 cursor-pointer z-40 text-amaranth">
            <svg
              id="burger"
              stroke-width="1px"
              width="40px"
              fill="#D32232"
              class="openmenu m-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 30 30"
            >
              <path class="top" d="M0 9h30v2H0z" />
              <path class="mid" d="M0 14h30v2H0z" />
              <path class="bot" d="M0 19h30v2H0z" />
            </svg>
            <p class="menu-text text-base m-auto font-epilogue_r">menu</p>
          </div>
        </div>
      </div>
        <!-- <router-link v-for="(item, index) in menu" :key="index" class="mr-6 py-2 px-4 rounded-md  hover:bg-gray-700 hover:bg-opacity-50 transition duration-500 ease-in-out" :to="item.path">
          {{item.title}}
        </router-link> -->
      </div>
  <!-- </teleport> -->
</template>

<script>
import MenuArrow from "./MenuArrow.vue"
import Logo from "./Logo.vue"
var lv = {}
lv.body = document.getElementsByTagName('BODY')[0]

export default {
  data() {
    return {
      menu: this.globals.data.menu
    }
  },
  components:{ MenuArrow,Logo},
  mounted() {
    this.menuToggle = this.gsap
      .timeline({ paused: true, reversed: true })
      /*
      .to("body", 0.1, {
        overflow: "hidden"
      })
      */
      .call(this.switchOverflowOut)
      .call(this.switchOverflowIn)
      .to(".menu-wrapper", 0.2, {
        display: "block",
        opacity: 1
      })
      .to(".openmenu",0.1,{
        fill: "#fff"
      },"-=0.2")
      .to(".menu-text ",0.2,{
        text: "sluiten"
      },"-=0.2")
      .to(".hamburger-button",0.1,{
        color: "#fff"
      },"-=0.2")
      .to(".top", 0.2, { y: "-9px", transformOrigin: "50% 50%" }, "-=0.2")
      .to(".bot", 0.2, { y: "9px", transformOrigin: "50% 50%" }, "-=0.2")
      .to(".mid", 0.2, { scale: 0.1, transformOrigin: "50% 50%" }, "-=0.2")
      .add("rotate")
      .to(".top", 0.2, { y: "5" }, "rotate")
      .to(".bot", 0.2, { y: "-5" }, "rotate")
      .to(".top", 0.2, { rotationZ: 45, transformOrigin: "50% 50%" }, "rotate")
      .to(".bot",0.2,
        { rotationZ: -45, transformOrigin: "50% 50%" },
        "rotate"
      );
  },
  methods:{
    toggleMenu(who) {
      if(this.menuToggle.reversed()){
        this.menuToggle.play()
      }else{
        if(who == 'x'){
          this.menuToggle.reverse()
          return
        }
        this.gsap.to('#arrow_' + who, {duration: 0.4, x: '20vw', ease:'power4.in'})
        this.gsap.delayedCall(0.3, this.menuReversed)
      }
    },
    menuReversed(){
      this.gsap.set('.arrow', {delay: 0.4, x: 0})
      this.menuToggle.reverse()
    },
    switchOverflowIn(){
      lv.body_width_offset = this.getScrollbarCompensation()
      lv.body.style.overflowY = 'hidden'
      this.gsap.set('#navwrap',{'padding-right': lv.body_width_offset + 'px'})
      lv.body.style.paddingRight = lv.body_width_offset + 'px'
    },
    switchOverflowOut(){
      lv.body.style.overflowY = 'scroll'
      lv.body.style.paddingRight = 0
      this.gsap.set('#navwrap',{'padding-right': 0 + 'px'})
    },
    getScrollbarCompensation() {
      lv.start_width = lv.body.offsetWidth
      lv.body.style.overflowY = 'hidden'
      lv.end_width = lv.body.offsetWidth
      lv.body.style.overflowY = 'scroll'
      return lv.end_width - lv.start_width
    }
  }
};



</script>

<style scoped>
</style>