export var globals = {
    //host:'http://kalender.multitudemo.nl',
    //host:'https://kalender.multitudemo.nl',
    host:'https://goudeerlijkeverhalen.nl',
    //host:'http://localhost',
    //host:'',
    section_tool: false,
    api_mode: 'normal'
}

export default globals
