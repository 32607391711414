<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 50 44"
    fill="none"
    class=""
  >
    <g clip-path="url(#clip0)">
      <path
        d="M49.0569 19.7325L30.2666 0.942185C29.6687 0.361484 28.8663 0.0394036 28.0328 0.045495C27.1993 0.0515864 26.4017 0.385337 25.8123 0.974715C25.223 1.56409 24.8891 2.36174 24.883 3.19522C24.8769 4.02871 25.1991 4.83111 25.7798 5.42904L38.1744 17.8237L2.72572 17.8237C2.35902 17.8237 1.99596 17.8963 1.65749 18.0374C1.31901 18.1785 1.01186 18.3852 0.753726 18.6456C0.495586 18.9061 0.291572 19.2151 0.153511 19.5548C0.0154506 19.8945 -0.0539447 20.2582 -0.0506706 20.6249L-0.0506706 21.79C-0.0539447 22.1567 0.0154506 22.5204 0.153511 22.8601C0.291572 23.1998 0.495586 23.5088 0.753726 23.7692C1.01186 24.0297 1.31901 24.2364 1.65749 24.3775C1.99596 24.5186 2.35902 24.5912 2.72572 24.5912L39.5874 24.5912L25.7798 38.3988C25.3361 38.8445 25.0337 39.4111 24.9104 40.0277C24.7871 40.6443 24.8483 41.2836 25.0864 41.8656C25.3245 42.4476 25.7289 42.9466 26.2491 43.3C26.7692 43.6534 27.382 43.8455 28.0108 43.8524C28.8578 43.8496 29.6691 43.5108 30.2666 42.9104L49.0569 24.1202C49.6221 23.5296 49.9376 22.7437 49.9376 21.9263C49.9376 21.1089 49.6221 20.3231 49.0569 19.7325Z"
        fill="white"
        class="group-menu-hover:text-perfume fill-current transition ease-in-out"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="50" height="43.9514" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
