import globals from './globals'
import * as cheet from 'cheet.js'
import emitt from './emitt'

import axios from 'axios'
import sha256 from 'crypto-js/sha256'

var lv = {}
lv.keysource = 'd8heyrgftyr6775ytugh5y4h8u5j994e5e4d5f4t55njf984yg4h55y4hu5j4i8632k1i878uoooplw2841'
lv.url_api = globals.host + '/_api/api3.php'

export var xhr = {
    fetch_main(){
        lv.props = {}
        lv.props.task = 'main'
        lv.props.event = 'data_main'
        xhr.call(lv.props)
    },
    call(props){
        lv.data = props
        lv.data['mode'] = globals.api_mode
        lv.data_string = JSON.stringify(lv.data)
        lv.key = xhr.makeKey(lv.data_string)
        axios.post(lv.url_api, {
            task: props.task,
            data: lv.data_string,
            key: lv.key
        }, { withCredentials: true })
        .then(function (response) {
            emitt.emit('fetch_main_done', response.data)
        })
        .catch(function (error) {
            lv.error = {
                result: 'error',
                info: 'Something went wrong... try again later. Sorry!',
                error: error
            }
            console.log(error)
            emitt.emit('fetch_main_done', lv.error)
        })
    },
    makeKey(str) {
        lv.keysplit = lv.keysource.split('55')
        return sha256(lv.keysplit[1] + str).toString()
    },
    dom(e){

        if(process.env.NODE_ENV !== 'production'){
            return
        }

        lv.stringPageCur = JSON.stringify(globals.page_cur)
        lv.pageSerial = xhr.makeKey(lv.stringPageCur)
        e.pageSerial = lv.pageSerial
        lv.serialName = e['fullslug'].replace(new RegExp('/', 'g'), '_')
        lv.serialCheck = globals.data.serials[lv.serialName]

        lv.data = JSON.stringify(e)
        lv.key = xhr.makeKey(lv.data)

        /*
        console.log('xhr dom')
        console.log(e)
        console.log('stringPageCur: ')
        console.log(lv.stringPageCur)
        console.log('serialName: ')
        console.log(lv.serialName)
        console.log('pageSerial: ')
        console.log(lv.pageSerial)
        console.log('serialCheck: ')
        console.log(lv.serialCheck)
        */

        if(lv.serialCheck === lv.pageSerial){
            //console.log('serial and key match, no need to send')
            return
        }
        //console.log('serial and key dont match, need to send')

        lv.url_dom = globals.host + '/_dom/dom.php'
        axios.post(lv.url_dom, {
            data: lv.data,
            key: lv.key
        }, { withCredentials: true })
        .then(function (response) {
            console.log(response.data)
        })
    },
    getAdminUser(){
        axios.get(globals.host + '/kalender/users/me', {
        }, { withCredentials: true })
        .then(function (response) {
            console.log(response)
            globals.admin_user = response.data.data
            emitt.emit('admin_user_done')
        })
        .catch(function (error) {
            console.log(error)
            emitt.emit('admin_user_done')
        })
    }
}

emitt.on('dom_xhr', (e) => {
    xhr.dom(e)
})
  
emitt.on('get_admin_user', (e) => {
    xhr.getAdminUser()
})
  
export default xhr
