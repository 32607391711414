import { createRouter, createWebHistory } from 'vue-router'
import globals from '@/modules/globals'
import emitt from '@/modules/emitt'

import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

let popped = false
let scrollPositions = {}

const routes = [
  {
    path: '/preview',
    name: 'preview',
    component: () => import('@/components/PreviewMode.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page_handler',
    component: () => import('@/components/Page_handler.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
	scrollBehavior(to, from) {
		var pageY = 0
		if (scrollPositions[to.path]) {
			if (popped) {
				pageY = scrollPositions[to.path]
			}
		}
    popped = false
    //console.log('scrollBehavior')
		gsap.to(window, {duration: 0.4, scrollTo: {y: pageY, x: 0}, ease: 'power2.inOut' })
	}
})

router.beforeEach((to, from, next) => {
  histY = 0
  scrollPositions[from.path] = window.pageYOffset
  //console.log('current pageY: ' + window.pageYOffset)
	gsap.to('#curtain_main', {delay: 0.2, duration: 0.3, display: 'block', opacity: 1, ease: 'power3.inOut', onComplete: function() {
    next()
  }})
})
router.afterEach((to, from) => {
	gsap.to('#curtain_main', {delay: 0.4, duration: 0.5, display: 'none', opacity: 0, ease: 'power3.inOut'})
  gsap.delayedCall(0.3,domHandle,[to])
})

let histY = 0
window.onpopstate = function(event) {
  popped = true
  if(event.state.scroll){
    histY = event.state.scroll.top
  }
}

function domHandle(p){
  if(p.name == 'preview'){
    return
  }
  let docTitle = globals.data.meta.title
  if(p.fullPath != '/'){
    docTitle = globals.page_cur['title'] + ' - ' + globals.data.meta.title
  }
  document.title = docTitle
  let domdata = {}
  domdata['fullslug'] = p.fullPath
  domdata['seo'] = {}
  domdata['seo']['title'] = globals.page_cur['title']
  domdata['seo']['description'] = globals.page_cur['description']
  domdata['seo']['featured_image'] = globals.page_cur['featured_image']
  domdata['dom'] = document.getElementById('app').innerHTML
  emitt.emit('dom_xhr', domdata)
}

export default router
