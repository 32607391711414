import { createApp } from 'vue'
import App from '@/App.vue'
import ErrrorApp from '@/ErrrorApp.vue'
import router from '@/router'

import { globals } from '@/modules/globals'
import emitt from '@/modules/emitt'
import xhr from '@/modules/xhr'

import gsap from 'gsap'
import { TextPlugin } from "gsap/TextPlugin"

gsap.registerPlugin(TextPlugin);
import '@/assets/styles/_tailwind.css'
import '@/assets/styles/main.css'

var app
function app_start() {
    app = createApp(App)
    app.config.globalProperties.globals = globals
    app.config.globalProperties.emitter = emitt
    app.config.globalProperties.gsap = gsap
    app.use(router)
    app.mount('#app')
}

let fetchCount = 0
xhr.fetch_main()
emitt.on('fetch_main_done', (e) => {
    globals.data = e.data
    fetchCount++
    if(fetchCount > 1){
        router.push({ path: '/overzicht'})
        return
    }
    if(e.result === 'ok'){
        app_start()
    }
    if(e.result === 'error'){
        app = createApp(ErrrorApp)
        app.mount('#app')
    }
})
emitt.on('fetch_previewMode', (e) => {
    xhr.fetch_main()
})
