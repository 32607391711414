<template>
  <div class=" min-h-full relative overflow-hidden">
    <!-- <div class="mode-center"> -->

      <!--  
      <Navbar class="relative z-20" />
      -->
      <Navbar />

      <router-view />
    <!-- </div> -->
    <Footer />
  </div>

  <div class="fixed inset-0 bg-magnitude z-10" id="curtain_main"></div>

</template>

<script>
import * as cheet from 'cheet.js'

import globals from '@/modules/globals'
import Navbar from '@/components/ui/Navbar'
import Footer from '@/components/ui/Footer'

export default {
  components: {
    Navbar, Footer
  },
  data() {
    return {}
  },
  mounted() {

    console.log(globals)

    cheet('g l o b a l s', () => {
      console.log(this.globals)
    })

    cheet('s e t 2 0 2 2', () => {
      console.log('set2022')
      this.globals.data.date_info.current_year = '2022'
    })

    // hijack a href to router
    window.addEventListener("click", (event) => {
      let { target } = event;
      while (target && target.tagName !== "A") target = target.parentNode;
      if (target && target.matches("a:not([href*='://'])") && target.href) {
        if (target && target.matches("a:not([href*='mailto:'])") && target.href) {
          const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented} = event;
          if (metaKey || altKey || ctrlKey || shiftKey) return
          if (defaultPrevented) return
          if (button !== undefined && button !== 0) return
          if (target && target.getAttribute) {
            const linkTarget = target.getAttribute("target")
            if (/\b_blank\b/i.test(linkTarget)) return
          }
          const url = new URL(target.href)
          const to = url.pathname
          if (window.location.pathname !== to && event.preventDefault) {
            event.preventDefault()
            this.$router.push(to)
          }
        }
      }
    })

  },
  updated: function () {},
  methods: {
  },
}
</script>


<style>
</style>
