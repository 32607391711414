<template>
  <div class="error">
    Oops, something went wrong
  </div>

</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  mounted() {}
}
</script>

<style scoped>
  .error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin:20px;
    padding:20px;
    border-radius:10px;
    background: rgba(0,0,0,0.25);
  }
</style>
